@import '../../styles/globals';

.resource {
    text-align: left;
    position: relative;

    &.with-comment {
        .empty {
            padding: 15px 0 22px 0;
        }

        footer {
            padding-top: 10px;
        }
    }

    &.current-user {
        box-shadow: $material-shadow-purple;
    }

    header {
        overflow: hidden;
        padding-bottom: 10px;

        h3 {
            font-size: 16px;
            float: left;
            color: $purple;
        }

        .floating-menu {
            position: absolute;
            top: 21px;
            right: 18px;

            > ul {
                top: -10px;
            }
        }
    }

    .taken {
        padding: 20px 0 8px 0;

        svg {
            margin-right: 10px;
        }

        .user {
            padding-bottom: 10px;

            .user-image {
                img {
                    position: relative;
                    top: 10px;
                }
            }
        }

        .time {
            padding-bottom: 10px;

            ul {
                float: right;
                position: relative;
                top: -3px;
                left: 5px;
                overflow: hidden;

                li {
                    float: left;
                    margin-left: 4px;

                    button {  
                        font-size: 10px;
                        padding: 4px 4px 5px 4px;
                    }
                }
            }
        }
    }

    .empty {
        text-align: center;
        padding: 30px 0 6px 0;

        svg {
            font-size: 60px;
            opacity: 0.5;
        }
    }

    .comment {
        opacity: 0.7;

        span {
            display: inline-block;
        }
    }

    footer {
        padding-top: 30px;
        text-align: center; 

        > button {
            padding: 5px 30px;
            text-align: center;
        }
    }
}

.resource-edit-modal {
    h4 {
        color: $purple;
        font-size: 18px;
        padding-bottom: 20px;
    }

    > div {
        text-align: left;
        font-size: 14px;
        padding: 5px;

        label {
            display: inline-block;
            width: 85px;
        }
    }

    button.save {
        margin-top: 20px;
    }
}

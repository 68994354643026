@import './styles/globals';

.site-header {
    padding: 20px 40px;
    overflow: hidden;

    h1 {
        color: $purple;
        font-size: 28px;
        float: left;
    }

    ul {
        float: right;

        li {
            margin-left: 20px;
            line-height: 33px;;
            float: left;
        }
    }
}
@import '../../styles/globals';

.features-page {
    text-align: center;
    background-color: $background-grey-dark;

    .container {
        display: inline-block;
        width: 1024px;
        text-align: left;
        background-color: $white;
        border-top: 2px solid $background-grey-dark;
        padding: 40px 0;
    }

    article {
        padding: 40px 0 30px 150px;
        border-bottom: 1px solid $border-grey;

        h4 {
            font-size: 20px;
            padding-bottom: 20px;
            color: $purple;
        }

        .list {
            li {
                padding-bottom: 15px;
                
                .ic {
                    display: inline-block;
                    text-align: left;
                    width: 35px;
                }
            }
        }

        &.organization {
            ul {
                overflow: hidden;
                display: inline-block;
            
                li {
                    padding: 0 10px;
                    float: left;
            
                    &.arrow {
                        padding-top: 100px;
                        font-size: 36px;
                    }
                
                    h5 {
                        font-size: 16px;
                        padding-bottom: 10px;
                    }
                
                    img {
                        width: 200px;
                        height: 200px;
                    }
                }
            }
        }
    }
}

@media (max-width: $max-resp-width) {
    .features-page {
        padding: 0 20px;

        .container {
            width: auto;
            padding: 0;
        }

        article {
            padding: 40px;

            &.organization {
                ul {
                    li {
                        padding-bottom: 20px;
                        display: block;

                        &.arrow {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
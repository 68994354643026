@import '../../styles/globals';

.org-settings {
    h3 {
        padding-bottom: 40px;
        color: $purple;
        font-size: 20px;

        small {
            color: $text-grey;
            padding-left: 5px;
        }
    }

    > div {
        padding-bottom: 20px;

        > label {
            display: inline-block;
            width: 65px;
        }

        > span {
            display: inline-block;
        }

        &.logo {
            padding-bottom: 40px;

            small {
                padding-left: 10px;
            }
        }

        &.integrations {
            border-top: 1px solid $border-grey;
            padding-top: 40px;
            margin-bottom: 20px;

            h4 {
                color: $purple;
                padding-bottom: 40px;
            }

            .slack {
                h5 {
                    font-size: 16px;
                    font-weight: 700;
                    padding-bottom: 20px;

                    svg {
                        margin-right: 5px;
                    }
                }

                > div {
                    padding-bottom: 20px;

                    label {
                        display: inline-block;
                        min-width: 160px;
                        padding-bottom: 10px;
                    }

                    .select-container {
                        min-width: 170px;
                        margin-right: 25px;
                        font-size: 14px;

                        .select__control {
                            border: 1px solid $border-grey;
                            border-radius: 20px;
                        }
                    }
                }
            }
        }

        &.users {
            margin-bottom: 20px;
            padding-top: 40px;
            border-top: 1px solid $border-grey;

            h4 {
                color: $purple;
                padding-bottom: 40px;
            }

            ul {
                display: inline-block;

                li {
                    border-bottom: 1px solid $background-purple;
                    padding: 20px 0;

                    &:first-child {
                        padding-top: 0;
                    }

                    > label {
                        display: inline-block;
                        width: 130px;
                    }

                    .select-container {
                        min-width: 120px;
                        margin-right: 25px;
                        font-size: 14px;

                        .select__control {
                            border: 1px solid $border-grey;
                            border-radius: 20px;
                        }
                    }

                    .curr-user-role {
                        display: inline-block;
                        text-transform: capitalize;
                        padding-left: 10px;
                        font-size: 14px;
                        color: $text-black;
                    }

                    .user-image {
                        vertical-align: middle;
                    }
                }
            }

            small {
                padding-top: 20px;
                display: block;
            }
        }
    }

    > button {
        color: $white;
        background: $purple;
        font-size: 14px;
    }

    > span {
        display: inline-block;
        padding-left: 20px;
        color: $purple;
        font-size: 14px;
    }
}

@media (max-width: $max-resp-width) {
    .org-settings {
        h3 {
            small {
                padding-left: 0;
            }
        }

        > div {
            &.logo {
                small {
                    display: block;
                    padding: 10px 0 0 0;
                }
            }

            .select-container {
                margin-top: 15px !important;
            }
        }
    }
}
@import '../../styles/globals';

.editable-text {
    position: relative;
    color: $purple;
    font-size: 16px;

    &.invalid {
        color: $red;
        
        svg {
            position: absolute;
            right: 12px;
            top: -2px;
            margin: 5px;
        }
    }
    
    input {
        border: 0;
        font-size: 16px;
        width: 130px;
        color: $text-grey;
        padding: 0 0 2px 0;
        border-bottom: 1px solid $purple;
        outline: none;
        border-radius: 0;
    }

    button {
        position: absolute;
        right: 12px;
        top: -2px;
        border: 0;
        padding: 5px;
    }
}
@import '../../styles/globals';

.user-settings {
    h3 {
        padding-bottom: 40px;
        color: $purple;
        font-size: 20px;

        small {
            color: $text-grey;
            padding-left: 5px;
        }
    }

    > div {
        padding-bottom: 20px;

        &.api-tokens {
            margin-bottom: 20px;
            display: inline-block;

            h4 {
                color: $purple;
                padding-bottom: 30px;
            }

            ul {
                padding-bottom: 20px;

                li {
                    border-bottom: 1px solid $background-purple;
                    padding: 20px 0;

                    &:first-child {
                        padding-top: 0;
                    }

                    span {
                        display: inline-block;
                        padding-right: 15px;
                        width: 140px;
                    }

                    input {
                        width: 100px;
                        margin-right: 34px;
                        border-color: $purple;
                    }
                }
            }

            small {
                margin-top: -15px;
                margin-bottom: 25px;
                display: block;
                padding-top: 10px;
                color: $purple;
            }
        }
    }

}

@media (max-width: $max-resp-width) {
    .user-settings {
        h3 {
            small {
                padding-left: 0;
            }
        }

        > div {
            &.api-tokens {
                ul {
                    li {
                        button {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
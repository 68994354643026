@import '../../styles/globals';

.queue {
    position: relative;

    > span {
        opacity: 0.5;
        display: inline-block;
        vertical-align: top;
    }

    ul {
        overflow: hidden;
        display: inline-block;
        position: absolute;
        top: 0;
        min-width: 120px;

        li {
            float: left;

            .user-image {
                margin-right: 5px;
                width: 27px;
                height: 27px;
                line-height: 27px;

                img {
                    width: 27px;
                    height: 27px;
                }
            }
        }
    }
}
@import '../../styles/globals';

.site-home {
    padding: 0 30px;

    header {
        font-size: 32px;
        font-weight: 700;
        color: $purple;
        text-align: center;
        padding-top: 25px;
    }

    .content {
        text-align: center;
        position: relative;

        .content-container {
            padding-top: 60px;
            display: inline-block;
            vertical-align: top;

            .quick-start {
                border: 1px solid $border-grey;
                padding: 40px;
                border-radius: 20px;
                display: inline-block;
                text-align: center;
                vertical-align: top;

                h3 {
                    font-size: 20px;
                    padding-bottom: 40px;
                    color: $purple;
                }

                button {
                    color: $white;
                    background: $purple;
                }

                .google {
                    padding-bottom: 20px;
                }

                > small {
                    padding-top: 30px;
                    display: inline-block;
                    font-size: 12px;
                    max-width: 230px;

                    > button {
                        background: none;
                        border: none;
                        color: $purple;
                        padding: 0;
                    }
                }
            }

            .features {
                text-align: left;
                padding: 20px 35px;
                max-width: 380px;

                h3 {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 0 20px 10px 0;
                }

                ul {
                    display: inline-block;

                    li {
                        display: inline-block;
                        padding-right: 20px;

                        &:last-child {
                            .ic {
                                width: 30px;
                            }
                        }
                        
                        .ic {
                            display: inline-block;
                            text-align: left;
                            width: 25px;
                        }

                        a {
                            padding-top: 10px;
                        }
                    }
                }

                .seperator {
                    border-top: 1px solid $border-grey;
                    padding-bottom: 10px;
                }

                a {
                    display: block;
                }
            }    
        }

        .image-container {
            display: inline-block;

            img {
                position: relative;
                margin-left: 120px;
                width: 62vh;
                height: 62vh;
                top: calc(41.3vh - 195px);
                z-index: 1;
            }
        }
    }

    footer {
        position: fixed;
        bottom: 0;
        left: 0;
        background: $blue;
        height: 60px;
        width: 100%;
        z-index: 0;

        ul {
            color: $white;
            padding: 0 20px;

            li {
                display: inline-block;
                padding: 20px 10px;

                a {
                    color: $white;
                }
            }
        }
    }
}

@media (max-width: $max-resp-width) {
    .site-home {
        .content  {
            .quick-start {
                top: 0;
            }

            .features {
                ul {
                    li {
                        display: block; 
                        padding-bottom: 10px;
                    }
                }
            }
            
            .image-container {
                display: block;

                img {
                    width: 260px;
                    height: 260px;
                    margin-top: 20px;
                    margin-left: 0;
                    top: 20px;
                }
            }
        }

        footer {
            position: relative;
            width: 100vw;
            height: 80px;
            left: -30px;

            ul {
                padding: 10px 30px;
            }
        }
    }
}
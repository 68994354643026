@import '../../styles/globals';

.env-page {
    .select-container {
        border-right: 1px solid $text-grey;
        margin-right: 20px;
        padding-right: 10px;
        line-height: 24px;
    }

    > header {
        overflow: hidden;
        padding-bottom: 20px;
        border-bottom: 1px solid $border-grey;
        margin-bottom: 20px;
        font-size: 17px;

        h3 {
            float: left;
            color: $text-black;
        }
        
        > span {
            float: left;
            padding-left: 20px;
            font-weight: 700;
        }

        .editable-text {
            position: relative;
            float: left;
            color: $text-black;
            font-size: 18px;
            margin-left: 20px;
            font-weight: 700;

            button {
                font-size: 12px;
                right: -50px;
            }

            input {
                font-size: 18px;
            }
        }

        > button {
            float: left;

            &.enable {
                float: right;
            }

            &.back {
                float: left;
                padding: 0;
                border: 0;
                font-size: 16px;
                line-height: 24px;
                margin-right: 20px;
            }
        }
        
        .queue-box {
            float: left;
            padding-left: 100px;
            height: 25px;

            .queue {
                float: right;
                padding: 0 10px 0 20px;
            }

            button {
                float: left;
            }
        }
    }
}

@media (max-width: $max-resp-width) {
    .env-page {
        > header {
            font-size: 14px;
            padding-bottom: 10px;

            .editable-text {
                font-size: 14px;

                input {
                    font-size: 14px;
                }

                button {
                    display: none;
                }
            }

            .queue-box {
                display: none;
            }
        }
    }
}
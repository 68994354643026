$purple:  #7237FF;
$background-purple: #e4d9ff;
$white:  white;
$background-grey: #FCFBFB;
$background-grey-dark: #ededed;
$text-grey: #757575;
$text-black: #3d3d3d;
$red: #fa2529;
$blue: #1e66eb;
$border-grey: #e3e1e1;
$material-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
$material-shadow-purple: 0 3px 6px $background-purple, 0 3px 6px $purple;
$max-resp-width: 1024px;
@import './styles/globals';

.app {
  > header {
    background-color: $background-grey;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    position: relative;

    h1 {
      display: inline-block;
      background-color: $purple;

      > a {
        display: inline-block;
        color: $white;
        font-size: 18px;
        padding: 20px 50px;
      }
    }

    nav {
      display: inline-block;
      font-weight: 700;
      color: $text-black;
      
      > a {
        padding: 0 40px;
        color: $text-black;
        line-height: 24px;
        border-right: 1px solid $text-black;
      }
      
      > span {
        padding: 0 40px;
        display: inline-block;

        &.env-selector {
          .fa-cubes {
            font-size: 20px;
          }
        }
      }

      i {
        border-left: 1px solid $text-black;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        top: -2px;
        position: relative;
      }

      .select-container {
        .select__control {
          background-color: $background-grey;
        }
      }
    }

    .user {
      float: right;
      line-height: 60px;
      padding-right: 20px;

      .user-image {
        img {
            position: relative;
            top: 10px;
        }
      }
    }

    .org {
      float: right;
      line-height: 30px;
      margin: 15px 30px;
      padding: 0 30px;
      border-right: 1px solid $text-black;

      img {
        margin-right: 10px;
        height: 30px;
        float: left;
      }
    }

    .floating-menu {
      float: right;
      position: relative;
      padding-right: 20px;

      > button {
        font-size: 14px;
        line-height: 60px;
        background: $background-grey;
      }

      > ul {
        top: 50px;
        left: -165px;
        width: 150px;

        li {
          padding: 15px 0;

          &:first-child {
            > button {
              margin-left: 7px;
            }
          }

          > svg {
            font-size: 14px;
          }

          > button {
            font-size: 15px;
            margin-left: 12px;
          }
        }
      }
    }
  }

  .app-route-view {
    padding: 40px;
  }
}

.onboarding-modal {
  color: $purple;
  margin-top: 100px !important;

  h3 {
    font-size: 26px;
    padding-bottom: 10px;
  }

  h4 {
    font-size: 18px;
    padding-bottom: 40px;
  }

  ul {
    padding-bottom: 40px;
    overflow: hidden;

    li {
      padding: 0 10px;
      float: left;

      &.arrow {
        padding-top: 100px;
        font-size: 36px;
      }

      h5 {
        font-size: 16px;
        padding-bottom: 10px;
      }

      img {
        width: 200px;
        height: 200px;
      }
    }
  }

  button.start {
    color: white;
    background: $purple;
    font-size: 16px;
    padding: 10px 100px;
    border-radius: 30px;
  }
}

.onboarding-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.75);
}

@media (max-width: $max-resp-width) {
  .app {
    > header {
      .org {
        display: none;
      }

      nav {
        display: none;
      }

      i.fr {
        display: none;
      }
    }

    .floating-menu {
      ul {
        left: -110px;
      }
    }
  }

  .onboarding-overlay {
    overflow-y: scroll;
  }

  .onboarding-modal {
    border: none !important;
    border-radius: 0 !important;
    margin-top: 0 !important;
    text-align: center;

    ul {
      li {
          padding-bottom: 20px;
          float: none;
          display: inline-block;

          &.arrow {
              display: none;
          }
      }
    }
  }
}
@import '../../styles/globals';

.login {
    text-align: center;

    .error {
        color: $red;
        padding-top: 40px;

        small {
            display: block;
            padding-top: 10px;
        }
    }

    .body {
        margin-top: 100px;
        padding: 60px;
        border: 1px solid $border-grey;
        display: inline-block;
        border-radius: 20px;

        header {
            display: block;
            font-size: 20px;
            padding-bottom: 40px;
        }

        button {
            color: $white;
            background: $purple;
        }

        .google {
            padding-bottom: 20px;
        }
    }
}

@media (max-width: $max-resp-width) {
    .login {
        padding: 40px;

        .body {
            padding: 40px;
            margin-top: 40px;
        }
    }
}
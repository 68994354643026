@import '../../styles/globals';

.floating-menu {
    button {
        border: 0;
        padding: 0;
    }

    ul {
        position: absolute;
        z-index: 999;
        left: 5px;
        top: 0;
        background: $white;
        border: 1px solid $background-purple;
        border-radius: 5px;
        padding: 0 10px;
        width: 85px;

        li { 
            border-bottom: 1px solid $background-purple;
            padding: 10px 0;
            text-align: left;

            &:last-child {
                border-bottom: 0;
            }

            > svg {
                font-size: 12px;
                position: relative;
                top: -1px;
            }

            > button {
                margin-left: 8px;
                font-size: 14px;
            }
        }
    }
}
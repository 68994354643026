@import '../../styles/globals';

.project-settings {
    > header {
        overflow: hidden;
        padding-bottom: 20px;
        border-bottom: 1px solid $border-grey;
        margin-bottom: 20px;

        h3 {
            position: relative;
            float: left;
            color: $text-black;
            font-size: 20px;

            &.settings {
                font-weight: 700;
            }
        }

        > span {
            float: left;
            font-size: 20px;
            padding: 0 20px;
        }

        > button {
            &.back {
                float: left;
                padding: 0;
                border: 0;
                font-size: 16px;
                line-height: 24px;
                margin-right: 20px;
            }
        }
    }

    .type-selector {
        padding: 10px 0;

        label {
            display: inline-block;
            padding-left: 10px;
        }
    }

    .permission-users {
        padding: 10px 20px;

        > div {
            display: inline-block;
            border-bottom: 1px solid $border-grey;
            padding-bottom: 10px;
        }

        > ul {
            padding-top: 10px;

            li {
                padding-bottom: 10px;

                .user-image {
                    vertical-align: bottom;
                }

                > span {
                    display: inline-block;
                    min-width: 140px;
                }
            }
        }
    }

    > button {
        color: $white;
        background: $purple;
        font-size: 14px;
        margin-top: 20px;
    }

    > span {
        display: inline-block;
        padding-left: 20px;
        color: $purple;
        font-size: 14px;
    }
}

@media (max-width: $max-resp-width) {
    .project-settings {
        > header {
            padding-bottom: 10px;

            h3, span {
                font-size: 16px;
            }
        }

        .type-selector {
            input {
                vertical-align: top;
            }

            label {
                max-width: 240px;
            }
        }

        .permission-users {
            > div {
                .select-container {
                    min-width: 170px;
                }
            }

            ul {
                li {
                    > span {
                        font-size: 14px;
                        min-width: 110px;
                    }
                }
            }
        }
    }
}
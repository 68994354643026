@import '../../styles/globals';

.project {
    .editable-text {
        padding-bottom: 40px;
        text-align: left;
    }

    .floating-menu {
        position: absolute;
        top: 21px;
        right: 18px;
    }
    
    .count {
        text-align: left;
        color: $text-grey;
        font-size: 16px;
        padding-bottom: 10px;

        span {
            padding-left: 10px;
        }
    }

    > button {
        margin-top: 40px;
    }
}
@import '../../styles/globals';

.env {
    .empty {
        text-align: center;
        padding: 20px 0;

        svg {
            font-size: 60px;
            opacity: 0.5;
        }
    }

    .editable-text {
        text-align: left;
        padding-bottom: 15px;
    }

    .floating-menu {
        position: absolute;
        top: 21px;
        right: 18px;
    }
    
    .count {
        text-align: left;
        color: $text-grey;
        font-size: 16px;
        padding: 30px 0 50px 0;

        span {
            padding-left: 10px;
        }
    }

    .disabled {
        position: absolute;
        top: 135px;
        left: 80px;
        font-size: 14px;
        opacity: 0.7;
    }

    > button {
        margin-top: 20px;
    }
}
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #757575; }

ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal; }

button {
  cursor: pointer;
  outline: none;
  border: 1px solid #7237FF;
  color: #7237FF;
  border-radius: 15px;
  padding: 5px 20px;
  background: white; }
  button.grey {
    color: #757575;
    border-color: #757575; }

a {
  text-decoration: none;
  color: #7237FF;
  display: inline-block; }

input[type="text"] {
  font-size: 14px;
  color: #757575;
  border: #e3e1e1 1px solid;
  padding: 8px 10px;
  outline: none;
  border-radius: 20px; }

.material-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.material-box {
  display: inline-block;
  width: 220px;
  height: 220px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-size: 14px; }

.select-container {
  display: inline-block;
  min-width: 200px;
  color: #757575; }
  .select-container .select__control {
    border: none;
    box-shadow: none; }
    .select-container .select__control .select__indicator-separator {
      display: none; }
    .select-container .select__control .select__indicator {
      color: #757575; }
  .select-container .select__menu .select__option {
    font-weight: 400;
    background-color: white; }
    .select-container .select__menu .select__option.select__option--is-selected {
      color: #7237FF;
      font-weight: 700; }
    .select-container .select__menu .select__option.select__option--is-focused {
      background-color: #e4d9ff; }

.ReactModal__Overlay {
  text-align: center; }
  .ReactModal__Overlay .ReactModal__Content {
    text-align: center;
    background: white;
    margin-top: 200px;
    display: inline-block;
    border: 1px solid #e3e1e1;
    padding: 40px;
    border-radius: 10px;
    outline: none;
    position: relative; }
    .ReactModal__Overlay .ReactModal__Content .close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 0;
      border: 0;
      font-size: 14px; }


.entity-list > ul {
  overflow: hidden; }
  .entity-list > ul > li {
    position: relative;
    margin: 20px;
    text-align: center;
    float: left; }
    .entity-list > ul > li .new {
      background-color: #FCFBFB;
      color: #7237FF; }
      .entity-list > ul > li .new.loading > div svg {
        -webkit-animation: loading-animation 0.75s linear 0s infinite alternate;
                animation: loading-animation 0.75s linear 0s infinite alternate; }
      .entity-list > ul > li .new.loading button {
        opacity: 0.5; }
      .entity-list > ul > li .new h3 {
        font-weight: 700; }
      .entity-list > ul > li .new > div {
        padding-top: 40px; }
        .entity-list > ul > li .new > div svg {
          font-size: 60px;
          opacity: 0.5; }
      .entity-list > ul > li .new button {
        margin-top: 35px;
        color: white;
        background: #7237FF; }

.delete-modal small {
  padding: 10px 0 20px 0;
  display: block;
  color: #7237FF; }

.delete-modal button {
  margin-right: 10px; }

.delete-modal h4 span {
  color: #7237FF;
  font-weight: 700; }

@-webkit-keyframes loading-animation {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); } }

@keyframes loading-animation {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); } }

.editable-text {
  position: relative;
  color: #7237FF;
  font-size: 16px; }
  .editable-text.invalid {
    color: #fa2529; }
    .editable-text.invalid svg {
      position: absolute;
      right: 12px;
      top: -2px;
      margin: 5px; }
  .editable-text input {
    border: 0;
    font-size: 16px;
    width: 130px;
    color: #757575;
    padding: 0 0 2px 0;
    border-bottom: 1px solid #7237FF;
    outline: none;
    border-radius: 0; }
  .editable-text button {
    position: absolute;
    right: 12px;
    top: -2px;
    border: 0;
    padding: 5px; }

.project .editable-text {
  padding-bottom: 40px;
  text-align: left; }

.project .floating-menu {
  position: absolute;
  top: 21px;
  right: 18px; }

.project .count {
  text-align: left;
  color: #757575;
  font-size: 16px;
  padding-bottom: 10px; }
  .project .count span {
    padding-left: 10px; }

.project > button {
  margin-top: 40px; }

.floating-menu button {
  border: 0;
  padding: 0; }

.floating-menu ul {
  position: absolute;
  z-index: 999;
  left: 5px;
  top: 0;
  background: white;
  border: 1px solid #e4d9ff;
  border-radius: 5px;
  padding: 0 10px;
  width: 85px; }
  .floating-menu ul li {
    border-bottom: 1px solid #e4d9ff;
    padding: 10px 0;
    text-align: left; }
    .floating-menu ul li:last-child {
      border-bottom: 0; }
    .floating-menu ul li > svg {
      font-size: 12px;
      position: relative;
      top: -1px; }
    .floating-menu ul li > button {
      margin-left: 8px;
      font-size: 14px; }

.site-home {
  padding: 0 30px; }
  .site-home header {
    font-size: 32px;
    font-weight: 700;
    color: #7237FF;
    text-align: center;
    padding-top: 25px; }
  .site-home .content {
    text-align: center;
    position: relative; }
    .site-home .content .content-container {
      padding-top: 60px;
      display: inline-block;
      vertical-align: top; }
      .site-home .content .content-container .quick-start {
        border: 1px solid #e3e1e1;
        padding: 40px;
        border-radius: 20px;
        display: inline-block;
        text-align: center;
        vertical-align: top; }
        .site-home .content .content-container .quick-start h3 {
          font-size: 20px;
          padding-bottom: 40px;
          color: #7237FF; }
        .site-home .content .content-container .quick-start button {
          color: white;
          background: #7237FF; }
        .site-home .content .content-container .quick-start .google {
          padding-bottom: 20px; }
        .site-home .content .content-container .quick-start > small {
          padding-top: 30px;
          display: inline-block;
          font-size: 12px;
          max-width: 230px; }
          .site-home .content .content-container .quick-start > small > button {
            background: none;
            border: none;
            color: #7237FF;
            padding: 0; }
      .site-home .content .content-container .features {
        text-align: left;
        padding: 20px 35px;
        max-width: 380px; }
        .site-home .content .content-container .features h3 {
          display: inline-block;
          font-size: 16px;
          font-weight: 700;
          padding: 0 20px 10px 0; }
        .site-home .content .content-container .features ul {
          display: inline-block; }
          .site-home .content .content-container .features ul li {
            display: inline-block;
            padding-right: 20px; }
            .site-home .content .content-container .features ul li:last-child .ic {
              width: 30px; }
            .site-home .content .content-container .features ul li .ic {
              display: inline-block;
              text-align: left;
              width: 25px; }
            .site-home .content .content-container .features ul li a {
              padding-top: 10px; }
        .site-home .content .content-container .features .seperator {
          border-top: 1px solid #e3e1e1;
          padding-bottom: 10px; }
        .site-home .content .content-container .features a {
          display: block; }
    .site-home .content .image-container {
      display: inline-block; }
      .site-home .content .image-container img {
        position: relative;
        margin-left: 120px;
        width: 62vh;
        height: 62vh;
        top: calc(41.3vh - 195px);
        z-index: 1; }
  .site-home footer {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #1e66eb;
    height: 60px;
    width: 100%;
    z-index: 0; }
    .site-home footer ul {
      color: white;
      padding: 0 20px; }
      .site-home footer ul li {
        display: inline-block;
        padding: 20px 10px; }
        .site-home footer ul li a {
          color: white; }

@media (max-width: 1024px) {
  .site-home .content .quick-start {
    top: 0; }
  .site-home .content .features ul li {
    display: block;
    padding-bottom: 10px; }
  .site-home .content .image-container {
    display: block; }
    .site-home .content .image-container img {
      width: 260px;
      height: 260px;
      margin-top: 20px;
      margin-left: 0;
      top: 20px; }
  .site-home footer {
    position: relative;
    width: 100vw;
    height: 80px;
    left: -30px; }
    .site-home footer ul {
      padding: 10px 30px; } }

.login-button {
  font-size: 14px;
  width: 210px;
  height: 35px; }
  .login-button svg {
    margin-right: 10px; }
  .login-button span {
    text-transform: capitalize; }

.project-page > header {
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e1e1;
  margin-bottom: 20px; }
  .project-page > header .editable-text {
    position: relative;
    float: left;
    color: #3d3d3d;
    font-size: 20px; }
    .project-page > header .editable-text button {
      font-size: 12px;
      right: -50px; }
    .project-page > header .editable-text input {
      font-size: 20px; }
  .project-page > header > button.settings {
    float: right; }
  .project-page > header > button.back {
    float: left;
    padding: 0;
    border: 0;
    font-size: 16px;
    line-height: 24px;
    margin-right: 20px; }

@media (max-width: 1024px) {
  .project-page > header .editable-text {
    font-size: 16px; }
    .project-page > header .editable-text input {
      font-size: 16px; }
    .project-page > header .editable-text button {
      display: none; } }


.env .empty {
  text-align: center;
  padding: 20px 0; }
  .env .empty svg {
    font-size: 60px;
    opacity: 0.5; }

.env .editable-text {
  text-align: left;
  padding-bottom: 15px; }

.env .floating-menu {
  position: absolute;
  top: 21px;
  right: 18px; }

.env .count {
  text-align: left;
  color: #757575;
  font-size: 16px;
  padding: 30px 0 50px 0; }
  .env .count span {
    padding-left: 10px; }

.env .disabled {
  position: absolute;
  top: 135px;
  left: 80px;
  font-size: 14px;
  opacity: 0.7; }

.env > button {
  margin-top: 20px; }

.user-image {
  display: inline-block;
  width: 30px;
  height: 31px;
  line-height: 30px;
  margin-right: 10px; }
  .user-image * {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-block; }
  .user-image span {
    background-color: #7237FF;
    color: white;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase; }

.org-settings h3 {
  padding-bottom: 40px;
  color: #7237FF;
  font-size: 20px; }
  .org-settings h3 small {
    color: #757575;
    padding-left: 5px; }

.org-settings > div {
  padding-bottom: 20px; }
  .org-settings > div > label {
    display: inline-block;
    width: 65px; }
  .org-settings > div > span {
    display: inline-block; }
  .org-settings > div.logo {
    padding-bottom: 40px; }
    .org-settings > div.logo small {
      padding-left: 10px; }
  .org-settings > div.integrations {
    border-top: 1px solid #e3e1e1;
    padding-top: 40px;
    margin-bottom: 20px; }
    .org-settings > div.integrations h4 {
      color: #7237FF;
      padding-bottom: 40px; }
    .org-settings > div.integrations .slack h5 {
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 20px; }
      .org-settings > div.integrations .slack h5 svg {
        margin-right: 5px; }
    .org-settings > div.integrations .slack > div {
      padding-bottom: 20px; }
      .org-settings > div.integrations .slack > div label {
        display: inline-block;
        min-width: 160px;
        padding-bottom: 10px; }
      .org-settings > div.integrations .slack > div .select-container {
        min-width: 170px;
        margin-right: 25px;
        font-size: 14px; }
        .org-settings > div.integrations .slack > div .select-container .select__control {
          border: 1px solid #e3e1e1;
          border-radius: 20px; }
  .org-settings > div.users {
    margin-bottom: 20px;
    padding-top: 40px;
    border-top: 1px solid #e3e1e1; }
    .org-settings > div.users h4 {
      color: #7237FF;
      padding-bottom: 40px; }
    .org-settings > div.users ul {
      display: inline-block; }
      .org-settings > div.users ul li {
        border-bottom: 1px solid #e4d9ff;
        padding: 20px 0; }
        .org-settings > div.users ul li:first-child {
          padding-top: 0; }
        .org-settings > div.users ul li > label {
          display: inline-block;
          width: 130px; }
        .org-settings > div.users ul li .select-container {
          min-width: 120px;
          margin-right: 25px;
          font-size: 14px; }
          .org-settings > div.users ul li .select-container .select__control {
            border: 1px solid #e3e1e1;
            border-radius: 20px; }
        .org-settings > div.users ul li .curr-user-role {
          display: inline-block;
          text-transform: capitalize;
          padding-left: 10px;
          font-size: 14px;
          color: #3d3d3d; }
        .org-settings > div.users ul li .user-image {
          vertical-align: middle; }
    .org-settings > div.users small {
      padding-top: 20px;
      display: block; }

.org-settings > button {
  color: white;
  background: #7237FF;
  font-size: 14px; }

.org-settings > span {
  display: inline-block;
  padding-left: 20px;
  color: #7237FF;
  font-size: 14px; }

@media (max-width: 1024px) {
  .org-settings h3 small {
    padding-left: 0; }
  .org-settings > div.logo small {
    display: block;
    padding: 10px 0 0 0; }
  .org-settings > div .select-container {
    margin-top: 15px !important; } }

.user-settings h3 {
  padding-bottom: 40px;
  color: #7237FF;
  font-size: 20px; }
  .user-settings h3 small {
    color: #757575;
    padding-left: 5px; }

.user-settings > div {
  padding-bottom: 20px; }
  .user-settings > div.api-tokens {
    margin-bottom: 20px;
    display: inline-block; }
    .user-settings > div.api-tokens h4 {
      color: #7237FF;
      padding-bottom: 30px; }
    .user-settings > div.api-tokens ul {
      padding-bottom: 20px; }
      .user-settings > div.api-tokens ul li {
        border-bottom: 1px solid #e4d9ff;
        padding: 20px 0; }
        .user-settings > div.api-tokens ul li:first-child {
          padding-top: 0; }
        .user-settings > div.api-tokens ul li span {
          display: inline-block;
          padding-right: 15px;
          width: 140px; }
        .user-settings > div.api-tokens ul li input {
          width: 100px;
          margin-right: 34px;
          border-color: #7237FF; }
    .user-settings > div.api-tokens small {
      margin-top: -15px;
      margin-bottom: 25px;
      display: block;
      padding-top: 10px;
      color: #7237FF; }

@media (max-width: 1024px) {
  .user-settings h3 small {
    padding-left: 0; }
  .user-settings > div.api-tokens ul li button {
    margin-top: 10px; } }

.env-page .select-container {
  border-right: 1px solid #757575;
  margin-right: 20px;
  padding-right: 10px;
  line-height: 24px; }

.env-page > header {
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e1e1;
  margin-bottom: 20px;
  font-size: 17px; }
  .env-page > header h3 {
    float: left;
    color: #3d3d3d; }
  .env-page > header > span {
    float: left;
    padding-left: 20px;
    font-weight: 700; }
  .env-page > header .editable-text {
    position: relative;
    float: left;
    color: #3d3d3d;
    font-size: 18px;
    margin-left: 20px;
    font-weight: 700; }
    .env-page > header .editable-text button {
      font-size: 12px;
      right: -50px; }
    .env-page > header .editable-text input {
      font-size: 18px; }
  .env-page > header > button {
    float: left; }
    .env-page > header > button.enable {
      float: right; }
    .env-page > header > button.back {
      float: left;
      padding: 0;
      border: 0;
      font-size: 16px;
      line-height: 24px;
      margin-right: 20px; }
  .env-page > header .queue-box {
    float: left;
    padding-left: 100px;
    height: 25px; }
    .env-page > header .queue-box .queue {
      float: right;
      padding: 0 10px 0 20px; }
    .env-page > header .queue-box button {
      float: left; }

@media (max-width: 1024px) {
  .env-page > header {
    font-size: 14px;
    padding-bottom: 10px; }
    .env-page > header .editable-text {
      font-size: 14px; }
      .env-page > header .editable-text input {
        font-size: 14px; }
      .env-page > header .editable-text button {
        display: none; }
    .env-page > header .queue-box {
      display: none; } }

.queue {
  position: relative; }
  .queue > span {
    opacity: 0.5;
    display: inline-block;
    vertical-align: top; }
  .queue ul {
    overflow: hidden;
    display: inline-block;
    position: absolute;
    top: 0;
    min-width: 120px; }
    .queue ul li {
      float: left; }
      .queue ul li .user-image {
        margin-right: 5px;
        width: 27px;
        height: 27px;
        line-height: 27px; }
        .queue ul li .user-image img {
          width: 27px;
          height: 27px; }

.resource {
  text-align: left;
  position: relative; }
  .resource.with-comment .empty {
    padding: 15px 0 22px 0; }
  .resource.with-comment footer {
    padding-top: 10px; }
  .resource.current-user {
    box-shadow: 0 3px 6px #e4d9ff, 0 3px 6px #7237FF; }
  .resource header {
    overflow: hidden;
    padding-bottom: 10px; }
    .resource header h3 {
      font-size: 16px;
      float: left;
      color: #7237FF; }
    .resource header .floating-menu {
      position: absolute;
      top: 21px;
      right: 18px; }
      .resource header .floating-menu > ul {
        top: -10px; }
  .resource .taken {
    padding: 20px 0 8px 0; }
    .resource .taken svg {
      margin-right: 10px; }
    .resource .taken .user {
      padding-bottom: 10px; }
      .resource .taken .user .user-image img {
        position: relative;
        top: 10px; }
    .resource .taken .time {
      padding-bottom: 10px; }
      .resource .taken .time ul {
        float: right;
        position: relative;
        top: -3px;
        left: 5px;
        overflow: hidden; }
        .resource .taken .time ul li {
          float: left;
          margin-left: 4px; }
          .resource .taken .time ul li button {
            font-size: 10px;
            padding: 4px 4px 5px 4px; }
  .resource .empty {
    text-align: center;
    padding: 30px 0 6px 0; }
    .resource .empty svg {
      font-size: 60px;
      opacity: 0.5; }
  .resource .comment {
    opacity: 0.7; }
    .resource .comment span {
      display: inline-block; }
  .resource footer {
    padding-top: 30px;
    text-align: center; }
    .resource footer > button {
      padding: 5px 30px;
      text-align: center; }

.resource-edit-modal h4 {
  color: #7237FF;
  font-size: 18px;
  padding-bottom: 20px; }

.resource-edit-modal > div {
  text-align: left;
  font-size: 14px;
  padding: 5px; }
  .resource-edit-modal > div label {
    display: inline-block;
    width: 85px; }

.resource-edit-modal button.save {
  margin-top: 20px; }

.login {
  text-align: center; }
  .login .error {
    color: #fa2529;
    padding-top: 40px; }
    .login .error small {
      display: block;
      padding-top: 10px; }
  .login .body {
    margin-top: 100px;
    padding: 60px;
    border: 1px solid #e3e1e1;
    display: inline-block;
    border-radius: 20px; }
    .login .body header {
      display: block;
      font-size: 20px;
      padding-bottom: 40px; }
    .login .body button {
      color: white;
      background: #7237FF; }
    .login .body .google {
      padding-bottom: 20px; }

@media (max-width: 1024px) {
  .login {
    padding: 40px; }
    .login .body {
      padding: 40px;
      margin-top: 40px; } }

.features-page {
  text-align: center;
  background-color: #ededed; }
  .features-page .container {
    display: inline-block;
    width: 1024px;
    text-align: left;
    background-color: white;
    border-top: 2px solid #ededed;
    padding: 40px 0; }
  .features-page article {
    padding: 40px 0 30px 150px;
    border-bottom: 1px solid #e3e1e1; }
    .features-page article h4 {
      font-size: 20px;
      padding-bottom: 20px;
      color: #7237FF; }
    .features-page article .list li {
      padding-bottom: 15px; }
      .features-page article .list li .ic {
        display: inline-block;
        text-align: left;
        width: 35px; }
    .features-page article.organization ul {
      overflow: hidden;
      display: inline-block; }
      .features-page article.organization ul li {
        padding: 0 10px;
        float: left; }
        .features-page article.organization ul li.arrow {
          padding-top: 100px;
          font-size: 36px; }
        .features-page article.organization ul li h5 {
          font-size: 16px;
          padding-bottom: 10px; }
        .features-page article.organization ul li img {
          width: 200px;
          height: 200px; }

@media (max-width: 1024px) {
  .features-page {
    padding: 0 20px; }
    .features-page .container {
      width: auto;
      padding: 0; }
    .features-page article {
      padding: 40px; }
      .features-page article.organization ul li {
        padding-bottom: 20px;
        display: block; }
        .features-page article.organization ul li.arrow {
          display: none; } }

.app > header {
  background-color: #FCFBFB;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: relative; }
  .app > header h1 {
    display: inline-block;
    background-color: #7237FF; }
    .app > header h1 > a {
      display: inline-block;
      color: white;
      font-size: 18px;
      padding: 20px 50px; }
  .app > header nav {
    display: inline-block;
    font-weight: 700;
    color: #3d3d3d; }
    .app > header nav > a {
      padding: 0 40px;
      color: #3d3d3d;
      line-height: 24px;
      border-right: 1px solid #3d3d3d; }
    .app > header nav > span {
      padding: 0 40px;
      display: inline-block; }
      .app > header nav > span.env-selector .fa-cubes {
        font-size: 20px; }
    .app > header nav i {
      border-left: 1px solid #3d3d3d;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      top: -2px;
      position: relative; }
    .app > header nav .select-container .select__control {
      background-color: #FCFBFB; }
  .app > header .user {
    float: right;
    line-height: 60px;
    padding-right: 20px; }
    .app > header .user .user-image img {
      position: relative;
      top: 10px; }
  .app > header .org {
    float: right;
    line-height: 30px;
    margin: 15px 30px;
    padding: 0 30px;
    border-right: 1px solid #3d3d3d; }
    .app > header .org img {
      margin-right: 10px;
      height: 30px;
      float: left; }
  .app > header .floating-menu {
    float: right;
    position: relative;
    padding-right: 20px; }
    .app > header .floating-menu > button {
      font-size: 14px;
      line-height: 60px;
      background: #FCFBFB; }
    .app > header .floating-menu > ul {
      top: 50px;
      left: -165px;
      width: 150px; }
      .app > header .floating-menu > ul li {
        padding: 15px 0; }
        .app > header .floating-menu > ul li:first-child > button {
          margin-left: 7px; }
        .app > header .floating-menu > ul li > svg {
          font-size: 14px; }
        .app > header .floating-menu > ul li > button {
          font-size: 15px;
          margin-left: 12px; }

.app .app-route-view {
  padding: 40px; }

.onboarding-modal {
  color: #7237FF;
  margin-top: 100px !important; }
  .onboarding-modal h3 {
    font-size: 26px;
    padding-bottom: 10px; }
  .onboarding-modal h4 {
    font-size: 18px;
    padding-bottom: 40px; }
  .onboarding-modal ul {
    padding-bottom: 40px;
    overflow: hidden; }
    .onboarding-modal ul li {
      padding: 0 10px;
      float: left; }
      .onboarding-modal ul li.arrow {
        padding-top: 100px;
        font-size: 36px; }
      .onboarding-modal ul li h5 {
        font-size: 16px;
        padding-bottom: 10px; }
      .onboarding-modal ul li img {
        width: 200px;
        height: 200px; }
  .onboarding-modal button.start {
    color: white;
    background: #7237FF;
    font-size: 16px;
    padding: 10px 100px;
    border-radius: 30px; }

.onboarding-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.75); }

@media (max-width: 1024px) {
  .app > header .org {
    display: none; }
  .app > header nav {
    display: none; }
  .app > header i.fr {
    display: none; }
  .app .floating-menu ul {
    left: -110px; }
  .onboarding-overlay {
    overflow-y: scroll; }
  .onboarding-modal {
    border: none !important;
    border-radius: 0 !important;
    margin-top: 0 !important;
    text-align: center; }
    .onboarding-modal ul li {
      padding-bottom: 20px;
      float: none;
      display: inline-block; }
      .onboarding-modal ul li.arrow {
        display: none; } }

.project-settings > header {
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e1e1;
  margin-bottom: 20px; }
  .project-settings > header h3 {
    position: relative;
    float: left;
    color: #3d3d3d;
    font-size: 20px; }
    .project-settings > header h3.settings {
      font-weight: 700; }
  .project-settings > header > span {
    float: left;
    font-size: 20px;
    padding: 0 20px; }
  .project-settings > header > button.back {
    float: left;
    padding: 0;
    border: 0;
    font-size: 16px;
    line-height: 24px;
    margin-right: 20px; }

.project-settings .type-selector {
  padding: 10px 0; }
  .project-settings .type-selector label {
    display: inline-block;
    padding-left: 10px; }

.project-settings .permission-users {
  padding: 10px 20px; }
  .project-settings .permission-users > div {
    display: inline-block;
    border-bottom: 1px solid #e3e1e1;
    padding-bottom: 10px; }
  .project-settings .permission-users > ul {
    padding-top: 10px; }
    .project-settings .permission-users > ul li {
      padding-bottom: 10px; }
      .project-settings .permission-users > ul li .user-image {
        vertical-align: bottom; }
      .project-settings .permission-users > ul li > span {
        display: inline-block;
        min-width: 140px; }

.project-settings > button {
  color: white;
  background: #7237FF;
  font-size: 14px;
  margin-top: 20px; }

.project-settings > span {
  display: inline-block;
  padding-left: 20px;
  color: #7237FF;
  font-size: 14px; }

@media (max-width: 1024px) {
  .project-settings > header {
    padding-bottom: 10px; }
    .project-settings > header h3, .project-settings > header span {
      font-size: 16px; }
  .project-settings .type-selector input {
    vertical-align: top; }
  .project-settings .type-selector label {
    max-width: 240px; }
  .project-settings .permission-users > div .select-container {
    min-width: 170px; }
  .project-settings .permission-users ul li > span {
    font-size: 14px;
    min-width: 110px; } }

.site-header {
  padding: 20px 40px;
  overflow: hidden; }
  .site-header h1 {
    color: #7237FF;
    font-size: 28px;
    float: left; }
  .site-header ul {
    float: right; }
    .site-header ul li {
      margin-left: 20px;
      line-height: 33px;
      float: left; }


@import '../../styles/globals';

.user-image {
    display: inline-block;
    width: 30px;
    height: 31px;
    line-height: 30px;
    margin-right: 10px;

    * {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: inline-block;
    }

    span {
        background-color: $purple;
        color: $white;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
    }
}
@import '../../styles/globals';

.entity-list {
    > ul {
        overflow: hidden;
        
        > li {
            position: relative;
            margin: 20px;
            text-align: center;
            float: left;

            .new {
                background-color: $background-grey;
                color: $purple;

                &.loading {
                    > div svg {
                        animation: loading-animation 0.75s linear 0s infinite alternate;
                    }

                    button {
                        opacity: 0.5;
                    }
                }
                
                h3 {
                    font-weight: 700;
                }

                > div {
                    padding-top: 40px;
            
                    svg {
                        font-size: 60px;
                        opacity: 0.5;
                    }
                }

                button {
                    margin-top: 35px;
                    color: $white;
                    background: $purple;
                }
            }
        }
    }
}

.delete-modal {
    small {
        padding: 10px 0 20px 0;
        display: block;
        color: $purple;
    }

    button {
        margin-right: 10px;
    }

    h4 {
        span {
            color: $purple;
            font-weight: 700;
        }
    }
}

@keyframes loading-animation {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.3);
    }
}
@import './styles/globals';
@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-grey;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
}

button {
  cursor: pointer;
  outline: none;
  border: 1px solid $purple;
  color: $purple;
  border-radius: 15px;
  padding: 5px 20px;
  background: $white;

  &.grey {
    color: $text-grey;
    border-color: $text-grey;
  }
}

a {
  text-decoration: none;
  color: $purple;
  display: inline-block;
}

input[type="text"] {
  font-size: 14px;
  color: $text-grey;
  border: $border-grey 1px solid;
  padding: 8px 10px;
  outline: none;
  border-radius: 20px;
}

.material-shadow {
  box-shadow: $material-shadow;
}

.material-box {
  display: inline-block;
  width: 220px;
  height: 220px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: $material-shadow;
  font-size: 14px;
}

.select-container {
  display: inline-block;
  min-width: 200px;
  color: $text-grey;

  .select__control {
    border: none;
    box-shadow: none;

    .select__indicator-separator {
      display: none;
    }

    .select__indicator {
      color: $text-grey;
    }
  } 
  
  .select__menu {
    .select__option {
      font-weight: 400;
      background-color: $white;

      &.select__option--is-selected {
        color: $purple;
        font-weight: 700;
      }
  
      &.select__option--is-focused {
        background-color: $background-purple;
      }
    }
  }
}

.ReactModal__Overlay {
  text-align: center;

  .ReactModal__Content {
    text-align: center;
    background: $white;
    margin-top: 200px;
    display: inline-block;
    border: 1px solid $border-grey;
    padding: 40px;
    border-radius: 10px;
    outline: none;
    position: relative;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 0;
      border: 0;
      font-size: 14px;
    }
  }
}
@import '../../styles/globals';

.project-page {
    > header {
        overflow: hidden;
        padding-bottom: 20px;
        border-bottom: 1px solid $border-grey;
        margin-bottom: 20px;

        .editable-text {
            position: relative;
            float: left;
            color: $text-black;
            font-size: 20px;

            button {
                font-size: 12px;
                right: -50px;
            }

            input {
                font-size: 20px;
            }
        }

        > button {
            &.settings {
                float: right;
            }

            &.back {
                float: left;
                padding: 0;
                border: 0;
                font-size: 16px;
                line-height: 24px;
                margin-right: 20px;
            }
        }
    }
}

@media (max-width: $max-resp-width) {
    .project-page {
        > header {
            .editable-text {
                font-size: 16px;

                input {
                    font-size: 16px;
                }

                button {
                    display: none;
                }
            }
        }
    }
}
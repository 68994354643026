@import '../../styles/globals';

.login-button {
    font-size: 14px;
    width: 210px;
    height: 35px;

    svg {
        margin-right: 10px;
    }

    span {
        text-transform: capitalize;
    }
}